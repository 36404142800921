<template>
<v-navigation-drawer
        fixed
        :permanent="$vuetify.breakpoint.lgAndUp ? true : !this.showMenu"
        :style="$vuetify.breakpoint.mdAndDown ? 'max-height: 100% !important; z-index: 99 !important;' : ''"
        :right="$vuetify.breakpoint.lgAndUp"
        :bottom="$vuetify.breakpoint.mdAndDown"
        :class="$vuetify.breakpoint.lgAndUp ? 'pt-15' : ''"
        :width="$vuetify.breakpoint.lgAndUp ? 370 : '100%'"
    >
    <v-col
        v-if="(start || selectedEvent) || $vuetify.breakpoint.mdAndDown"
        style="position: sticky; top: 0; background: white; z-index: 2; margin-left: 1px;" class="px-3 pt-3">
        <v-btn
        @click.stop="$vuetify.breakpoint.mdAndDown ? $emit('closeMenu') : $emit('clearSelectedDates')"
        icon
        small
        fab
        color="black"
        >
        <v-icon>
            mdi-window-close
        </v-icon>
        </v-btn>
    </v-col>

    <template v-if="isAdmin">
      <reservation-modal
        v-if="calculator"
        :id="id"
        :start="start"
        :end="end"
        @loadReservationInSidebar="$emit('loadReservationInSidebar', $event)"
        @refetchEvents="$emit('refetchEvents')"
        />

      <reservation-modal-edit
        @loadReservationInSidebar="$emit('loadReservationInSidebar', $event)"
        @refetchEvents="$emit('refetchEvents')"
      />

      <reservation-delete-modal
        @removeReservationFromSidebar="$emit('removeReservationFromSidebar')"
      />
    </template>

    <reservation-preview-modal/>

    <calendar-sidebar-inquiry
        v-if="selectedEvent && selectedEvent.type === 'inquiry'"
        :event="selectedEvent"/>

    <calendar-sidebar-reservation
        v-else-if="selectedEvent && selectedEvent.type === 'reservation'"
        :event="selectedEvent"/>

    <template v-else-if="start">
      <calendar-sidebar-availability
        :id="id"
        :start="start"
        :end="end"
        :events="events"
        @updateStartEnd="$emit('updateStartEnd', $event)"
        @refetchEvents="$emit('refetchEvents')"/>

      <v-divider class="my-4"></v-divider>

      <calendar-sidebar-price
        :id="id"
        :start="start"
        :end="end"
        :specialPricing="specialPricing"
        :calendarRules="calendarRules"
        @priceChanged="$emit('priceChanged')"
        @refetchEvents="$emit('refetchEvents')"
      />

      <v-divider class="my-4"></v-divider>

      <calendar-sidebar-rules
        :id="id"
        :start="start"
        :end="end"
        :calendarRules="calendarRules"
        :calendarRulesDates="calendarRulesDates"
        @rulesChanged="$emit('rulesChanged')"
      />

    </template>
    <calendar-sidebar-empty-state
      v-else-if="!start"
    />

    <v-divider v-if="!selectedEvent" class="my-4"></v-divider>

    <div
      class="px-6 mt-3 mb-4"
      v-if="!selectedEvent"
    >
      <h6 class="text-h6 mb-4">{{ $t('Calendar tutorial title') }}</h6>
      <youtube-iframe
        :url="'https://www.youtube.com/embed/--eBo5jzgqk'"
      />
    </div>
</v-navigation-drawer>
</template>
<script>
import { mapGetters } from 'vuex';
import CalendarSidebarInquiry from '@/components/calendar/SidebarInquiry.vue';
import CalendarSidebarReservation from '@/components/calendar/SidebarReservation.vue';
import CalendarSidebarEmptyState from '@/components/calendar/SidebarEmptyState.vue';
import CalendarSidebarPrice from '@/components/calendar/SidebarPrice.vue';
import CalendarSidebarAvailability from '@/components/calendar/SidebarAvailability.vue';
import CalendarSidebarRules from '@/components/calendar/SidebarRules.vue';
import YoutubeIframe from '@/components/YoutubeIframe.vue';
import ReservationModalEdit from '@/components/reservations/ReservationModalEdit.vue';
import ReservationModal from '@/components/reservations/ReservationModal.vue';
import ReservationPreviewModal from '@/components/reservations/ReservationPreviewModal.vue';
import ReservationDeleteModal from '@/components/reservations/ReservationDeleteModal.vue';

export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    start: {
      required: true,
    },
    end: {
      required: true,
    },
    events: {
      required: true,
      type: Array,
    },
    selectedEvent: {
      required: false,
      type: Object,
    },
    specialPricing: {
      required: true,
      type: Object,
    },
    showMenu: {
      required: true,
      type: Boolean,
    },
    calendarRules: {
      required: true,
      type: Array,
    },
    calendarRulesDates: {
      required: true,
      type: Object,
    },
  },
  components: {
    CalendarSidebarInquiry,
    CalendarSidebarReservation,
    CalendarSidebarEmptyState,
    CalendarSidebarAvailability,
    CalendarSidebarPrice,
    CalendarSidebarRules,
    YoutubeIframe,
    ReservationModal,
    ReservationModalEdit,
    ReservationPreviewModal,
    ReservationDeleteModal,
  },
  computed: {
    ...mapGetters(['calculator', 'isAdmin']),
  },
};
</script>
