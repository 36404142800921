<template>
    <v-col class="px-6 pt-0">
        <v-alert elevation="0" class="pb-0 pl-0">
            <template v-slot:prepend>
                <v-avatar color="grey lighten-2" size="48" class="mb-auto mr-3 font-weight-medium">
                    <img
                      v-if="
                        event.guest.media_avatar
                        && Object.values(event.guest.media_avatar)
                        && Object.values(event.guest.media_avatar)[0]
                      "
                      :src="Object.values(event.guest.media_avatar)[0].original_url"
                      :alt="guest.first_name"
                    >
                    <span v-else>{{guest.first_name.charAt(0).toUpperCase()}}{{guest.last_name.charAt(0).toUpperCase()}}</span>
                </v-avatar>
            </template>
            <div class="d-flex flex-column">
                <span class="text-body-1">{{ $t('Reservation') }} #{{ event.booking_reference }}</span>
                <h6 class="text-h6">{{guest.first_name}} {{ guest.last_name }}</h6>
                <span>{{ humanFriendlyRangeString(event.start, event.end) }}</span>
                <span>{{$tc('guests_count', event.guests)}} · € {{event.total}}</span>
            </div>
        </v-alert>

        <span class="divider-thick"></span>

        <h6 class="text-h6 mb-3">{{ $t('Booking details') }}</h6>
        <reservation-event-details :event="event" :isAdmin="isAdmin"/>

        <span class="divider-thick"></span>

        <h5 class="text-h6 mb-3">{{ $t('Guest') }}</h5>
        <v-list class="py-0">
            <v-list-item two-line class="pl-0 my-2">
                <v-list-item-content>
                    <v-list-item-title class="mb-2">{{ $t('Full name') }}</v-list-item-title>
                    <v-list-item-subtitle>{{guest.first_name}} {{ guest.last_name }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <template v-if="isAdmin">
              <v-divider></v-divider>
              <v-list-item two-line class="pl-0 my-2">
                  <v-list-item-content>
                      <v-list-item-title class="mb-2">{{ $t('Email') }}</v-list-item-title>
                      <v-list-item-subtitle>{{ guest.email ? guest.email : 'No email' }}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
            </template>
            <template
              v-if="
                (!event.advance_payment || event.advance_payment_paid)
                || isAdmin
              "
            >
              <v-divider></v-divider>
              <v-list-item two-line class="pl-0 my-2">
                  <v-list-item-content>
                      <v-list-item-title class="mb-2">{{ $t('Phone number') }}</v-list-item-title>
                      <v-list-item-subtitle>{{guest.phone}}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
            </template>
        </v-list>

        <span class="divider-thick"></span>

        <h5 class="text-h6 mb-3">{{ $t('Total amount') }}:</h5>
        <div class="d-flex flex-column">
            <div class="d-flex align-items-center justify-space-between mt-3">
                <span class="font-weight-bold">{{ $t('Total') }} (EUR)</span>
                <span class="font-weight-bold">€ {{event.total}}</span>
            </div>
            <div class="d-flex align-items-center justify-space-between">
                <span>{{ $t('Service fee') }}</span>
                <span>-€ {{event.service_fee}}</span>
            </div>
            <div class="d-flex align-items-center justify-space-between">
                <span>{{ $t('TAX') }}</span>
                <span>-€ {{event.tax}}</span>
            </div>
            <v-divider class="mt-3"></v-divider>
            <div class="d-flex align-items-center justify-space-between mt-3">
                <span class="font-weight-bold">{{ $t('Your earn') }}</span>
                <span class="font-weight-bold">€ {{event.owner_total}}</span>
            </div>
        </div>

        <span class="divider-thick"></span>
        <div class="text-center">
          <v-btn
            text
            class="text-decoration-underline  text-capitalize cursor-pointer font-weight-medium text-subtitle-1"
            @click.stop="loadReservationPreview"
          >
            {{$t('View details')}}
          </v-btn>
          <br/>
          <v-btn
            v-if="showReportGuestNoShowButton"
            text
            class="text-decoration-underline  text-capitalize cursor-pointer font-weight-medium text-subtitle-1"
            @click.stop="showGuestNoShowModal = true"
          >
            {{ $t('Report guest no-show') }}
          </v-btn>
        </div>

        <div v-if="isAdmin">
            <span class="divider-thick"></span>
            <div class="text-center">
                <div class="mb-2">
                  <v-btn text class="text-uppercase" icon @click.stop="showReservationEmailSmsTemplatesModal = true"><v-icon>mdi-email-outline</v-icon></v-btn>
                  <v-btn text class="text-decoration-underline  text-uppercase cursor-pointer" @click.stop="editReservationId">Edit</v-btn>
                  <v-btn text class="text-decoration-underline  text-uppercase cursor-pointer" @click.stop="deleteReservation">Delete</v-btn>
                </div>
                <v-btn
                  v-if="event && event.advance_payment && event.advance_payment_paid"
                  @click.stop="showReservationSendEmailAdvancePaymentPaidModal = true"
                  text
                  block
                  class="cursor-pointer text-decoration-underline mt-2 mb-4">Pošalji sms i email <br> o uplati avansa</v-btn>
                <v-btn
                  v-if="event"
                  @click.stop="showReservationSendEmailEditedReservation = true"
                  text
                  block
                  class="cursor-pointer text-decoration-underline"
                >
                  Pošalji sms i email <br> o promeni rezervacije
                </v-btn>
            </div>

            <reservation-send-email-advance-payment-paid-modal
              v-if="showReservationSendEmailAdvancePaymentPaidModal"
              :show="showReservationSendEmailAdvancePaymentPaidModal"
              @close="showReservationSendEmailAdvancePaymentPaidModal = false"
              :reservationId="event.id"
            />
            <reservation-send-email-edited-reservation-modal
              v-if="showReservationSendEmailEditedReservation"
              :show="showReservationSendEmailEditedReservation"
              @close="showReservationSendEmailEditedReservation = false"
              :reservationId="event.id"
            />
            <reservation-email-sms-templates-modal
                v-if="showReservationEmailSmsTemplatesModal"
                :show="showReservationEmailSmsTemplatesModal"
                @close="showReservationEmailSmsTemplatesModal = false"
                :reservationId="event.id"
            />

            <guest-no-show-modal
                v-if="showGuestNoShowModal"
                :show="showGuestNoShowModal"
                @close="showGuestNoShowModal = false"
                :reservationId="event.id"
            />

        </div>

    </v-col>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { humanFriendlyRangeString, fullDateFormat } from '@/components/calendar/config';
import ReservationEventDetails from '@/components/reservations/ReservationEventDetails.vue';
import ReservationEmailSmsTemplatesModal from '@/components/reservations/ReservationEmailSmsTemplatesModal.vue';
import ReservationSendEmailAdvancePaymentPaidModal from '@/components/reservations/ReservationSendEmailAdvancePaymentPaidModal.vue';
import GuestNoShowModal from '@/components/reservations/GuestNoShowModal.vue';
import ReservationSendEmailEditedReservationModal from '@/components/reservations/ReservationSendEmailEditedReservationModal.vue';

export default {
  props: {
    event: {
      required: true,
      type: Object,
    },
  },
  components: {
    ReservationEventDetails,
    ReservationEmailSmsTemplatesModal,
    ReservationSendEmailAdvancePaymentPaidModal,
    ReservationSendEmailEditedReservationModal,
    GuestNoShowModal,
  },
  data() {
    return {
      showReservationEmailSmsTemplatesModal: false,
      showReservationSendEmailAdvancePaymentPaidModal: false,
      showReservationSendEmailEditedReservation: false,
      showGuestNoShowModal: false,
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'user']),
    guest() {
      if (this.event.guest) {
        return this.event.guest;
      }
      return null;
    },
    showReportGuestNoShowButton() {
      return (this.user.id !== this.event.guest_id) && moment().isBetween(
        moment(this.event.start).startOf('day'),
        moment(this.event.start).add(24, 'hours').endOf('day'),
      );
    },
  },
  methods: {
    humanFriendlyRangeString,
    fullDateFormat,
    editReservationId() {
      this.$store.dispatch('editReservationId', this.event.id);
    },
    deleteReservation() {
      this.$store.dispatch('deleteReservation', this.event);
    },
    loadReservationPreview() {
      this.$store.dispatch('setReservationPreview', this.event.id);
    },
  },
};
</script>
