<template>
    <component
        :show="Boolean(reservation)"
        v-bind:is="showInModal ? 'modal' : 'div'"
        title="Edit Reservation"
        @save="update"
        @close="close"
        @clear="clear"
        :hideActions="hideActionsIfNotGuestIsChoosen"
        :fullscreen="true"
        :sticky-header="true"
        :cssClass="`${loading ? 'disable-save' : ''}`"
    >
        <v-container
          v-if="reservation"
          fluid
        >
          <errors-backend/>
          <v-row >
            <v-col cols="12" lg="7" xl="8">
              <reservation-form
                :id="reservation.ad_id.toString()"
                @update="updatePaymentForm"
                ref="reservationForm"
                :reservation="reservation"
              />
              <v-col cols="12" md="6" xl="4" class="pb-0 mb-4 mt-1 pl-0 pr-0 pr-md-2">
                <v-card outlined class="px-3 py-2 d-flex align-center">
                  <span class="font-weight-medium text-body-1 mr-auto">Language</span>
                  <v-radio-group
                    row
                    v-model="form.lang"
                    class="mt-0 pl-5 text-capitalize hide-error-messages"
                  >
                    <v-radio :value="'sr'" label="Serbian"/>
                    <v-radio :value="'en'" label="English"/>
                  </v-radio-group>
                </v-card>
              </v-col>
              <div class="mt-10">
                <template v-if="!changeGuestActive">
                  <v-card-title class="pl-0 mb-0 pt-0 pb-2">Reservation guest</v-card-title>
                  <user-guestbook-card
                    :user="reservation.guest"
                    :type="reservation.guest_type"
                    @select="selectUser"
                  />
                  <v-btn
                    @click.stop="changeGuest"
                  >
                    Change guest
                  </v-btn>
                </template>
                <template v-else>
                  <guest-picker
                    v-if="changeGuestActive"
                    @selectUser="selectUser"
                    @selectGuestbook="selectGuestbook"
                    @newGuest="newGuest"
                    @reset="resetGuest"
                    :key="guestPickerKey"
                  />
                  <v-btn
                    @click.stop="resetToReservationGuest"
                    text
                    outlined
                  >Reset To Default
                  </v-btn>
                </template>
              </div>
              <v-text-field
                v-model="form.gacid"
                type="text"
                class="mt-2"
                outlined
                label="GACID"
                hint="Copy from Inquiry or Email"
                persistent-hint
              />
            </v-col>
            <v-col
              cols="12"
              lg="5"
              xl="4"
              style="border-left:1px solid #ccc;"
              class="px-0 mt-8 mt-lg-0"
            >
              <pricing-preview
                :allow-change-dates="true"
                :key="`${reservation.calculator.start}${reservation.calculator.end}`"
                :show="true"
                :id="reservation.ad_id.toString()"
                :start="reservation.start"
                :end="reservation.end"
                :customPrice="form.custom_pricing ? form.custom_price : undefined"
                :calculator-data="reservation.calculator"
                :showInModal="false"
              />
            </v-col>
          </v-row>
        </v-container>
    </component>
</template>

<script>
import Modal from '@/components/Modal.vue';
import axios from 'axios';
import PricingPreview from '@/components/PricingPreview.vue';
import GuestPicker from '@/components/reservations/GuestPicker.vue';
import UserGuestbookCard from '@/components/reservations/UserGuestbookCard.vue';
import ReservationForm from '@/components/reservations/ReservationForm.vue';
import { mapGetters } from 'vuex';
import {
  RESERVATION_GUEST_TYPE_USER,
  RESERVATION_GUEST_TYPE_GUESTBOOK,
} from '@/components/calendar/config';

export default {
  provide() {
    return {
      datePickerRefresh: this.datePickerRefresh,
    };
  },
  props: {
    showInModal: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Modal,
    PricingPreview,
    UserGuestbookCard,
    GuestPicker,
    ReservationForm,
  },
  data() {
    return {
      form: {
        lang: undefined,
        guest_id: undefined,
        guest_type: undefined,
        new_guest: undefined,
        gacid: undefined,

        custom_pricing: false,
        custom_price: {
          total: undefined,
        },
        comment: undefined,
      },
      guestPickerKey: 1,
      datePickerRefresh: {
        datePickerKey: 1,
      },

      reservation: undefined,

      changeGuestActive: false,

    };
  },
  mounted() {
    if (this.calculator) {
      // console.log('has calculator');
    }
  },
  computed: {
    ...mapGetters(['editReservationId', 'calculator', 'loading']),
    hideActionsIfNotGuestIsChoosen() {
      if ((this.form.guest_id && this.form.guest_type) || (this.form.new_guest && this.form.new_guest.first_name && this.form.new_guest.last_name && this.form.new_guest.phone)) {
        return true;
      }
      return false;
    },
  },
  watch: {
    editReservationId(id) {
      if (id) {
        this.loadReservation(id);
      }
    },
  },
  methods: {
    loadReservation(id) {
      // Clear calculator from vuex.
      this.$store.commit('setPriceCalculator', null);
      this.$store
        .dispatch('loadReservation', id)
        .then((data) => {
          this.reservation = data;
          this.form.guest_id = this.reservation.guest_id;
          this.form.guest_type = this.reservation.guest_type;
          this.form.gacid = this.reservation.gacid;
          this.form.lang = this.reservation.lang;

          this.form.calculator = this.reservation.calculator;

          if (this.reservation.comment) {
            this.form.comment = this.reservation.comment;
          }
        });
    },
    update() {
      if (this.loading) {
        return;
      }
      this.$refs.reservationForm.validateForm();
      if (this.$refs.reservationForm.$v.$invalid) {
        const errorMessages = this.$refs.reservationForm.collectErrorMessages();
        // console.log('errorMessages', errorMessages);
        const alertMessage = errorMessages.join('\n');
        alert(alertMessage);
        return;
      }
      // if calculator changed after: we set calculator to NULL +  we already load reservation calculator in form.
      // than include calculator in request.
      if (this.calculator) {
        this.form.calculator = this.calculator;
      }
      axios
        .post(`calendar/reservations/${this.reservation.ad_id}/${this.reservation.id}`, {
          ...this.form,
        })
        .then(({ data }) => {
          this.$store.dispatch('message', 'Reservation Updated');
          this.$emit('loadReservationInSidebar', data.reservation_id);
          this.close();
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          if (error.response.data.errors.accommodation_unavailable) {
            this.$emit('refetchEvents');
            this.datePickerRefresh.datePickerKey += 1;
          }
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
    close() {
      this.clear();
      this.$store.dispatch('editReservationId', null);
      this.changeGuestActive = false;
    },
    clear() {
      this.form = {
        guest_id: undefined,
        guest_type: undefined,
        new_guest: undefined,
      };
      this.guestPickerKey += 1;
      this.reservation = undefined;
      this.$store.dispatch('clearErrors');
    },
    selectGuestbook(id) {
      this.form.guest_id = id;
      this.form.guest_type = RESERVATION_GUEST_TYPE_GUESTBOOK;
      // this.changeGuestActive = false;
    },
    selectUser(id) {
      this.form.guest_id = id;
      this.form.guest_type = RESERVATION_GUEST_TYPE_USER;
      // this.changeGuestActive = false;
    },
    resetGuest() {
      this.form.guest_id = undefined;
      this.form.guest_type = undefined;
      this.form.new_guest = undefined;
      this.changeGuestActive = true;
    },
    changeGuest() {
      this.form.guest_id = undefined;
      this.form.guest_type = undefined;
      this.form.new_guest = undefined;

      this.changeGuestActive = true;
    },
    newGuest(guest) {
      this.form.new_guest = guest;
      this.$store.dispatch('loading', false);
    },
    resetToReservationGuest() {
      this.form.guest_id = this.reservation.guest_id;
      this.form.guest_type = this.reservation.guest_type;
      this.form.new_guest = undefined;
      this.changeGuestActive = false;
    },
    updatePaymentForm(form) {
      // console.log('updatePaymentForm', form);
      // this.form.payment = form;
      this.form = { ...this.form, ...form };
    },
  },
};
</script>

<style scoped lang="scss">

</style>
